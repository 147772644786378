import React from 'react';
import styled, { keyframes } from 'styled-components';
import Layout from '../components/layout';
import { media } from '../style/style-utils';
import { Accent, Main } from '../components/typography';
import BetaForm from '../components/BetaForm';

import astralikerLogo from '../images/astraliker-logo.svg';

const Wrapper = styled.div`
  min-height: 700px;
  max-width: ${props => props.theme.contentWidth};
  padding: 45px 15px;;
  margin: auto;
  text-align: center;
`;

const ComingSoonPage = () => (
  <Layout>
    <Wrapper>
      <img width="200" src={astralikerLogo}/>
      <Accent tag='h1'>Astraliker is coming soon</Accent>
      <Main tag='p'>Sign up now to get early notification of our launch date!</Main>
      <BetaForm/>
    </Wrapper>
  </Layout>
);

export default ComingSoonPage;
