import styled, { css } from 'styled-components';
import DynamicComponent from './DynamicComponent';

export const AccentStyle = css`
  font-family: 'Montserrat', 'sans-serif';
  font-size: ${props => {
    switch (props.tag) {
      case 'h1':
        return '2.3em';
      case 'h2':
        return '1.6em';
      default:
        return '1.4em';
    }
  }};
  font-weight: 900;
  color: ${props => props.theme.darkGrey};
`;

const Accent = styled(DynamicComponent)`
  ${AccentStyle};
`;

export default Accent;
