import styled, { css } from 'styled-components';
import DynamicComponent from './DynamicComponent';

export const MainStyle = css`
  font-family: 'Montserrat', 'sans-serif';
  font-size: 1em;
  font-weight: 500;
  color: ${props => props.theme.darkGrey};
`;

const Main = styled(DynamicComponent)`
  ${MainStyle};
`;

export default Main;
