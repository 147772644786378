import styled, { css } from 'styled-components';
import DynamicComponent from './DynamicComponent';

export const ButtonStyle = css`
  font-family: 'Montserrat', 'sans-serif';
  font-size: 1.2em;
  font-weight: 500;
  color: ${props => (props.type === 'secondary' ? props.theme.grey : 'white')};
`;

const Button = styled(DynamicComponent)`
  ${ButtonStyle};
`;

export default Button;
