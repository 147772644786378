import React from 'react';
import styled from 'styled-components';

const StyledDynamicComponent = styled.div``;

const DynamicComponent = ({ tag = 'div', children, ...props }) => {
  return (
    <StyledDynamicComponent as={tag} {...props}>{children}</StyledDynamicComponent>
  );
};

DynamicComponent.defaultProps = {
  tag: 'div',
};

export default DynamicComponent;
