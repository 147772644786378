import styled, { css } from 'styled-components';
import DynamicComponent from './DynamicComponent';

export const MainStrongStyle = css`
  font-family: 'Montserrat', 'sans-serif';
  font-size: 20px;
  font-weight: 400;
  color: ${props => props.theme.colors.grey};
`;

const MainStrong = styled(DynamicComponent)`
  ${MainStrongStyle};
`;

export default MainStrong;
