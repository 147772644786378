import styled, { css } from 'styled-components';
import DynamicComponent from './DynamicComponent';

export const CaptionStyle = css`
  font-family: 'Montserrat', 'sans-serif';
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
  color: ${props => props.theme.colors.lightGrey};
`;

const Caption = styled(DynamicComponent)`
  ${CaptionStyle};
`;

export default Caption;
