import styled from 'styled-components';

const StyledLabel = styled.label`
  padding: 0 0 5px 0;
  display: block;
  color: ${props => props.theme.grey};
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 300;
  text-transform: uppercase;
`;

const StyledInput = styled.input`
  padding: 12px 12px;
  color: #3465d2;
  background: white;
  border: 3px solid ${props => (props.isValid ? props.theme.colors.lightGrey : 'red')};
  border-radius: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  width: 100%;
  box-sizing: border-box;

  &:hover {
    outline: none;
    border: 3px solid ${props => (props.isValid ? props.theme.colors.grey : 'red')};
  }

  &:focus {
    outline: none;
    border: 3px solid ${props => (props.isValid ? props.theme.colors.blue : 'red')};
  }
`;

const InputSeparator = styled.div`
  height: 14px;
`;


export { StyledLabel, StyledInput, InputSeparator }
