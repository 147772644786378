import React, { Component } from 'react';
import styled from 'styled-components';

import { StyledLabel, StyledInput } from '../components/Form';
import Button from './Button';
import { post } from '../utils/request';

const Wrapper = styled.div`
  max-width: 500px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 40px;
`;

const SubmitButton = styled(Button)`
  margin-top: 5px;
  width: 100%;
`;

class BetaForm extends Component {

  render() {
    return (
      <Wrapper>
        <form action="https://astraliker.us19.list-manage.com/subscribe/post?u=21ee6a1d1b3e83399eef5b8fb&amp;id=7041ec83c2" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
          <div id="mc_embed_signup_scroll">
            <div className="mc-field-group">
            	<StyledInput
                isValid type="email"
                name="EMAIL"
                className="required email" id="mce-EMAIL"
                placeholder="Email"
              />
            </div>
          	<div id="mce-responses" className="clear">
          		<div className="response" id="mce-error-response" style={{display: 'none'}}></div>
          		<div className="response" id="mce-success-response" style={{display: 'none'}}></div>
          	</div>
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
              <input type="text" name="b_21ee6a1d1b3e83399eef5b8fb_7041ec83c2" tabIndex="-1" onChange={() => {}} value=""/>
            </div>
            <div className="clear">
              <SubmitButton
                id="mc-embedded-subscribe"
                name="subscribe"
                type="submit"
              >
                GET NOTIFIED
              </SubmitButton>
            </div>
          </div>
        </form>
      </Wrapper>
    );
  }
}

export default BetaForm;
