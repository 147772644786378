import React from 'react';
import styled, { css } from 'styled-components';

export const ButtonStyle = css`
  font-family: 'Montserrat', 'sans-serif';
  font-size: 1em;
  font-weight: 500;
  color: white;
  text-decoration: none;
  margin-top: 5px;
  min-height: 50px;
  padding: 20px 30px;
  border-radius: 15px;
  background-color: ${props => props.theme.colors.blue};
  transition: box-shadow 0.4s linear, background-color 0.4s linear;

  &:hover {
    background-color: ${props => props.theme.colors.lightBlue};
    box-shadow: 0px 0px 4px 0px ${props => props.theme.colors.lightBlue};
  }
`;

const Button = styled.button`
  ${ButtonStyle};
`;

export default Button;

// margin-top: 5px;
// width: 100%;
// min-height: 50px;
// text-decoration: none;
// font-family: "Montserrat";
// font-size: 1em;
// font-weight: 500;
// padding: 10px 20px;
// border-radius: 15px;
// color: white;
// background-color: ${props => props.theme.colors.blue};
// transition: box-shadow 0.4s linear, background-color 0.4s linear;
//
// &:hover {
//   background-color: ${props => props.theme.colors.lightBlue};
//   box-shadow: 0px 0px 4px 0px ${props => props.theme.colors.lightBlue};
// }
